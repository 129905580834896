import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./components/contexts/UserContext";
import hotelIcon from "./images/happ.png";
import settingsIcon from "./images/icons/gear.svg";
import pinIcon from "./images/icons/pin.svg";
import unpinIcon from "./images/icons/unpin.svg";
import appsIcon from "./images/icons/apps.svg";
import BuildTime, { buildTimeString } from "./BuildTime";
import { ViewContext } from "./components/contexts/ViewContext";
import Settings from "./Settings";
import logoutIcon from "./images/icons/logout.svg";
import { setVersion, useVersion } from "./components/useVersion";
import Main from "./components/Main";
import reloadIcon from "./images/icons/reload.svg";
import Stats from "./Stats";
import AttendanceWidget from "./components/widgets/AttendanceWidget";
import { objectOrJSONparse } from "./components/functions/tools";

const pages: any = {
  calendar: "Kalendarz",
  attendance: "Czas pracy",
  menu_editor: "Edytor karty dań",
};

export default function Header({ currentPage }: { currentPage: string }) {
  const {
    loggedIn,
    username,
    userID,
    name,
    role,
    logOut,
    auth: authAll,
  } = useContext(UserContext);
  const auth: any = authAll && objectOrJSONparse(authAll["modules"]);
  const { modal, setModal, modal2, setModal2, pinned, setPinned } =
    useContext(ViewContext);

  const [userIDcheck, setUserIDCheck] = useState(userID);
  const [finishedUpdate, setFinishedUpdate] = useState(false);
  const versioning = useVersion(userIDcheck);

  const [dbSize, setDBSize] = useState<number>();

  async function fetchSize() {
    ("");
    const response = await fetch(
      process.env.NODE_ENV === "production"
        ? "https://data.agit.com.pl/hotelhub/backend/lsi/db-size-now.txt"
        : "https://data.agit.com.pl/hotelhub/backend/lsi_dev/db-size-now.txt"
    );
    const result = await response.text();
    setDBSize(Number(result));
  }

  useEffect(() => {
    fetchSize();
  }, []);

  if (userIDcheck && userID && versioning?.doUpdate) {
    setUserIDCheck(undefined);
    const modalParams = {
      heading: "Aktualizacja systemu",
      content: (
        <div style={{ width: "100%", padding: "5px 8px" }}>
          {finishedUpdate ? (
            <div>
              System zaktualizowano pomyślnie.
              <br />
              Za chwilę nastąpi odświeżenie strony.
            </div>
          ) : (
            <div>
              <div style={{ fontSize: "11px", color: "grey" }}>
                Wprowadzone zmiany:
              </div>
              <div
                style={{
                  fontSize: "12px",
                  padding: "5px",
                  // fontWeight: "bold",
                  background: "rgb(240,240,240)",
                  borderRadius: "5px",
                  boxShadow: "0 0 4px rgba(0,0,0,.3) inset",
                }}
              >
                <div>
                  {versioning?.map((ver: any) => (
                    <pre
                      style={{
                        marginTop: 0,
                        marginBottom: "5px",
                        fontFamily: "inherit",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {ver?.comment_public}
                    </pre>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div>
            <button
              className="normal-button"
              style={{
                marginTop: "5px",
                color: "white",
                fontWeight: "bold",
                background: "rgb(9,133,16)",
                width: "100%",
                padding: "15px 8px",
                boxSizing: "border-box",
              }}
              onClick={async () => {
                const result = await setVersion(
                  userID,
                  versioning.current_version
                );
                if (result?.response?.response === "OK") {
                  setFinishedUpdate(true);
                  window.setTimeout(() => window.location.reload(), 600);
                }
              }}
            >
              AKTUALIZUJ TERAZ
            </button>
          </div>
        </div>
      ),
      show: true,
      style: {
        width: "400px",
      },
    };
    if (modal && modal.show) {
    } else {
      setModal(modalParams);
    }
  }

  return (
    <div className={"mainInfo" + (!pinned ? " unpinned" : "")}>
      <Link className="link" to=".">
        <div
          className="logoGroup"
          title={buildTimeString + "\r\nRozmiar bazy LSI: " + dbSize + " MB"}
        >
          <img src={hotelIcon} />
          {/* <div className="mainTitle" ></div> */}
          <div
            style={{
              paddingLeft: "12px",
              textTransform: "uppercase",
              paddingRight: "5px",
            }}
          >
            {pages?.[currentPage]}
          </div>
          {/* <div className="buildTime">
            <BuildTime />
          </div> */}
        </div>
      </Link>
      <div className="userGroup">
        {/* {auth?.["attendance"] > 0 ? <AttendanceWidget /> : <></>} */}
        <AttendanceWidget />
        <Stats />
        <div className="micro-menu-toggle">
          <img
            style={{
              width: "18px",
              marginLeft: "2px",
              padding: "0 10px",
              marginRight: "10px",
            }}
            src={appsIcon}
          />
          <div className="micro-menu">
            <Main micro={true} />
          </div>
        </div>
        <div
          title={pinned ? "Przypięte" : "Autoukrywanie"}
          onClick={() => {
            setPinned((prev: Boolean) => !prev);
            window.localStorage.setItem("view-unpin-top", String(!pinned));
          }}
        >
          <img
            style={{
              width: "25px",
              margin: "0 2px",
            }}
            src={pinned ? pinIcon : unpinIcon}
          />
        </div>
        <div
          onClick={() =>
            window.confirm("Czy chcesz przeładować stronę?") &&
            window.location.reload()
          }
        >
          <img
            style={{
              width: "17px",
              opacity: ".6",
              margin: "0 2px",
            }}
            src={reloadIcon}
          />
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            setModal({
              show: true,
              heading: "Ustawienia",
              content: <Settings page={currentPage} />,
            })
          }
        >
          <img
            style={{
              width: "16px",
            }}
            src={settingsIcon}
          />
        </div>
        <span className="username" title={name}>
          {name?.split(" ")[0][0] + ". " + name?.split(" ")[1]}
        </span>{" "}
        <span className="userrole">
          {role === 0 ? "★★★" : role === 9 ? "★★" : "★"}
        </span>
        <button className="button little logout" onClick={() => logOut()}>
          <img className="log-out-icon" height={15} src={logoutIcon} />
          {/* Wyloguj */}
        </button>
      </div>
    </div>
  );
}
