import { useState } from "react";
import SalesSummary from "./ViewProductFull/SalesSummary";
import TransactionList from "./ViewProductFull/TransactionList";

export default function ViewProductFull({ data }: { data: any }) {
  const tabs = ["Podsumowanie sprzedaży", "Wykaz transakcji"];
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="view-product-details">
      <h1>{data?.nazwa}</h1>
      {/* | {data?.indeks} | {data?.nr_produktu}</h1>*/}
      <div className="product-tabs">
        {tabs?.map((t: any, index: number) => (
          <button
            key={index}
            className={activeTab === index ? "active" : ""}
            onClick={() => setActiveTab(index)}
          >
            {t}
          </button>
        ))}
      </div>
      <SalesSummary data={data} hide={activeTab === 0 ? false : true} />
      <TransactionList data={data} hide={activeTab === 1 ? false : true} />
    </div>
  );
}
