import { idText } from "typescript";
import insertGeneric from "../dataFetch/insertGeneric";
import { useContext, useEffect, useState } from "react";
import { ViewContext } from "../contexts/ViewContext";

export default function VoucherUpdateUseType({
  item,
  updateStatus,
  setUpdate,
  currentType,
  setCurrentType,
  show,
}: {
  item: any;
  updateStatus?: Function;
  setUpdate: Function;
  currentType: string | null;
  setCurrentType: Function;
  show?: Function;
}) {
  const { id, client_used_kind, status } = item;

  const { setModal2 } = useContext(ViewContext);

  function updateUseType(id: number, toType: string | null) {
    insertGeneric("set-voucher-use-type", {
      id: id,
      usedType: toType,
    });

    if (updateStatus) {
      updateStatus(id, 4);
      setModal2((prev: any) => ({ ...prev, show: false }));
    }
    setUpdate((prev: number) => prev + 1);
    setCurrentType(toType);
  }

  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
        width: "100%",
      }}
    >
      <button
        onClick={async () => {
          updateUseType(id, "SPA");
          if (show) {
            show(false);
          }
        }}
        className={"normal-button" + (currentType === "SPA" ? " active" : "")}
        style={{ width: "100%" }}
      >
        SPA
      </button>
      <button
        onClick={() => {
          updateUseType(id, "GASTRO");
          if (show) {
            show(false);
          }
        }}
        className={
          "normal-button" + (currentType === "GASTRO" ? " active" : "")
        }
        style={{ width: "100%" }}
      >
        Gastro
      </button>
      <button
        onClick={() => {
          updateUseType(id, "INNE");
          if (show) {
            show(false);
          }
        }}
        className={"normal-button" + (currentType === "INNE" ? " active" : "")}
        style={{ width: "100%" }}
      >
        inne
      </button>
      <button
        onClick={() => {
          updateUseType(id, null);
          if (show) {
            show(false);
          }
        }}
        className={
          "normal-button" +
          (currentType === null || !currentType ? " active" : "")
        }
        style={{ width: "100%" }}
      >
        brak
      </button>
    </div>
  );
}
