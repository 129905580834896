import prettyDate from "../functions/prettyDate";

export default function FindReceiptItem({ item }: { item: any }) {
  return (
    <div
      className="invoice-find"
      style={{
        gridTemplateColumns: "70px 80px 1fr 100px",
        alignItems: "center",
      }}
    >
      <div style={{ fontWeight: "500" }}>
        <span style={{ fontSize: "10px" }}>PAR/</span>
        {item.DocNo}
      </div>
      <div style={{ fontSize: "10px" }}>
        {new Date(item.data_dzienna.slice(0, 10)).toLocaleDateString()}
      </div>
      <div style={{ textAlign: "left" }}>{item.ko_firma}</div>
      <div style={{ textAlign: "right" }}>
        {Math.round(item.ValueBWal * 100) / 100} zł
      </div>
    </div>
  );
}
