export default function InvoiceNumber({
  item,
}: {
  item: { DocNo: any; month: any; year: any };
}) {
  return item.DocNo && item.month && item.year ? (
    <>
      FV/{item.DocNo}/{item.month}/{item.year}/H
    </>
  ) : <></>;
}
