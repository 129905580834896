import InvoiceNumber from "./InvoiceNumber";

export default function FindInvoiceItem({ item }: { item: any }) {
  return (
    <div className="invoice-find">
      <div style={{ fontWeight: "500" }}>
        <InvoiceNumber
          item={{ DocNo: item.DocNo, month: item.month, year: item.year }}
        />
      </div>
      <div style={{ textAlign: "left" }}>{item.ko_firma}</div>
      <div style={{ textAlign: "right" }}>
        {Math.round(item.ValueBWal * 100) / 100} zł
      </div>
    </div>
  );
}
