import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import ViewProduct from "./ViewProductFull";

export function schema(data: any, functions: any) {
  const years = [];
  const now = new Date();

  for (let i = now.getFullYear(); i > 2012; i--) {
    years.push(i);
  }
  return {
    activeFilter: false,
    edit: false,
    showFilters: true,
    view: true,
    viewContent: <ViewProduct data={data} />,
    filters: [
      {
        column: "date_start",
        name: "Od",
        element: "input",
        default: "2025-03-01",
        type: "date",
        visible: true,
      },
      {
        column: "date_end",
        name: "Od",
        default: "2025-03-07",
        element: "input",
        type: "date",
        visible: true,
      },
      {
        column: "Grupa",
        name: "Grupa",
        element: "select",
        getOptionsFrom: "lsi_product_groups",
      },
      // { column: "n", name: "Nazwa", element: "input" },
    ],
  };
}
