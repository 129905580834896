import { useEffect, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import FindInvoiceItem from "./FindInvoiceItem";
import Loading from "./Loading";
import InvoiceNumber from "./InvoiceNumber";
import FindReceiptItem from "./FindReceiptItem";

export default function FindInvoice({
  data,
  setData,
  hide,
  isInvoice,
  save,
}: {
  data: any;
  setData: Function;
  hide: Function;
  isInvoice: Boolean;
  save: Function;
}) {
  const [input, setInput] = useState("");
  const [box, setBox] = useState(false);
  const { loading, rows } = useGeneric({
    name: input
      ? isInvoice
        ? "lsi_invoice_query_basic"
        : "lsi_receipt_query_basic"
      : "",
    update: input,
    query: { multiQuery: input },
  });

  useEffect(() => {
    if (input && input.length > 0) {
      setBox(true);
    } else {
      setBox(false);
    }
  }, [input]);

  return (
    <div className="hybrid-search-wrap-outer">
      <input
        type="text"
        placeholder={
          "Szukaj " + (isInvoice ? "faktury" : "paragonu") + " w LSI"
        }
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onClick={() => setBox(true)}
        style={{
          borderRadius: "5px",
          border: "1px solid lightgrey",
          padding: "3px 5px",
        }}
      />
      {box ? (
        <div className="hybrid-search-results">
          {!loading && rows && rows.length ? (
            rows?.map((r: any) => (
              <div
                className="hybrid-search-result"
                onClick={() => {
                  setData(r);
                  setBox(false);
                  hide(true);
                  save(r);
                }}
              >
                {isInvoice ? (
                  <FindInvoiceItem item={r} />
                ) : (
                  <FindReceiptItem item={r} />
                )}
              </div>
            ))
          ) : (
            <div style={{ padding: "3px 5px" }}>
              {!input?.length && !rows?.length ? (
                <></>
              ) : loading && input ? (
                <Loading title={"Odpytuję LSI..."} little={true} />
              ) : (
                <>Nie znaleziono</>
              )}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
