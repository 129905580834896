import Loading from "../../../../common/Loading";
import useGeneric from "../../../../dataFetch/useGeneric";
import prettyNumber from "../../../../functions/prettyNumber";

export default function SalesSummary({
  data,
  hide,
}: {
  data: any;
  hide: Boolean;
}) {
  const { rows, loading } = useGeneric({
    name: "lsi_product_details",
    query: {
      id: data?.id,
    },
  });
  const p = !loading && rows?.[0];

  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  const lastYear = new Date();
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  const lastYearPrevMonth = new Date();
  lastYearPrevMonth.setFullYear(lastYearPrevMonth.getFullYear() - 1);
  lastYearPrevMonth.setMonth(lastYearPrevMonth.getMonth() - 1);

  return loading ? (
    <Loading little={true} />
  ) : (
    <div style={hide ? { display: "none" } : {}}>
      <table className="data-table">
        <thead>
          <tr>
            <th>Miesiąc</th>
            <th>Ilosć sprzedanych sztuk</th>
            <th>Łączna wartość sprzedaży</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Bieżący</th>
            <td>{prettyNumber(p?.amount_this_month, { round: 1 })}</td>
            <td>
              {prettyNumber(p?.value_this_month, { round: 2, postfix: "zł" })}
            </td>
          </tr>
          <tr>
            <th>
              {lastMonth?.toLocaleDateString("pl-PL", {
                month: "long",
                year: "numeric",
              })}
            </th>
            <td>{prettyNumber(p?.amount_prev_month, { round: 1 })}</td>
            <td>
              {prettyNumber(p?.value_prev_month, { round: 2, postfix: "zł" })}
            </td>
          </tr>
          <tr>
            <th>
              {twoMonthsAgo?.toLocaleDateString("pl-PL", {
                month: "long",
                year: "numeric",
              })}
            </th>
            <td>{prettyNumber(p?.amount_month_minus_2, { round: 1 })}</td>
            <td>
              {prettyNumber(p?.value_month_minus_2, {
                round: 2,
                postfix: "zł",
              })}
            </td>
          </tr>
          <tr>
            <th>
              {threeMonthsAgo?.toLocaleDateString("pl-PL", {
                month: "long",
                year: "numeric",
              })}
            </th>
            <td>{prettyNumber(p?.amount_month_minus_3, { round: 1 })}</td>
            <td>
              {prettyNumber(p?.value_month_minus_3, {
                round: 2,
                postfix: "zł",
              })}
            </td>
          </tr>
          <tr>
            <th>
              {lastYear?.toLocaleDateString("pl-PL", {
                month: "long",
                year: "numeric",
              })}
            </th>
            <td>
              {prettyNumber(p?.amount_same_month_last_year, { round: 1 })}
            </td>
            <td>
              {prettyNumber(p?.value_same_month_last_year, {
                round: 2,
                postfix: "zł",
              })}
            </td>
          </tr>
          <tr>
            <th>
              {lastYearPrevMonth?.toLocaleDateString("pl-PL", {
                month: "long",
                year: "numeric",
              })}
            </th>
            <td>
              {prettyNumber(p?.amount_prev_month_last_year, { round: 1 })}
            </td>
            <td>
              {prettyNumber(p?.value_prev_month_last_year, {
                round: 2,
                postfix: "zł",
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
