export const voucherStatus: any = {
  0: { text: "roboczy", textLong: "roboczy", className: "phase0" }, ///0
  1: {
    text: "do podania",
    textLong: "czeka na odbiór",
    className: "phase1",
  }, //1
  2: { text: "w hotelu", textLong: "dostępny", className: "phase2" }, //2
  3: { text: "wydany", textLong: "wydany klientowi", className: "phase3" }, //3
  4: { text: "użyty", textLong: "wykorzystany", className: "phase4" }, //4
  5: {
    text: "anulowany",
    textLong: "anulowany",
    className: "cancelled",
  },
};
