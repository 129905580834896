import prettyNumber from "../../../functions/prettyNumber";
import { URLProd } from "../../../paths";
import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  return [
    { source: "", name: "Miejsce", value: <div>{row?.place}</div> },
    {
      source: "Grupa",
      name: "Grupa",
      value: <div>{row?.Grupa}</div>,
      style: {
        width: "120px",
      },
    },
    {
      source: "nazwa",
      name: "Nazwa",
      value: <div>{row?.nazwa}</div>,
      style: {
        width: "45%",
        ...(row?.indeks?.match(/^KU-.*/)
          ? {
              color: "#18528c",
              //   color: "white",
            }
          : row?.indeks?.match(/ALKO/)
          ? {
              color: "#bc7b03",
            }
          : {}),
      },
    },
    {
      source: "ilosc_laczna",
      name: "Liczba sprzedanych szt.",
      value: <>{prettyNumber(row?.ilosc_laczna, { round: 1 })}</>,
      style: {
        width:"100px"
      }
    },
    {
      source: "wartosc_laczna",
      name: "Łączna wartość sprzedaży",
      value: <>{prettyNumber(row?.wartosc_laczna, { round: 2, postfix: "zł" })}</>,
    },
    {
      source: "",
      name: "W tym faktury",
      value: <>{prettyNumber(row?.wartosc_faktura / row?.wartosc_laczna * 100, { round: 2, postfix: "%" })}</>,
    },
    {
      source: "swz_laczna",
      name: "Średnia wartość zamówienia",
      value: <>{prettyNumber(row?.swz, { round: 2, postfix:"zł" })}</>,
    },
  ];
}
