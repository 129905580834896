import { useContext, useEffect, useState } from "react";
import insertGeneric from "../dataFetch/insertGeneric";
import prettyDate from "../functions/prettyDate";
import { UserContext } from "../contexts/UserContext";
import { objectOrJSONparse } from "../functions/tools";
import VoucherField from "./VoucherField";
import InvoiceSelector from "../calendar/InvoiceSelector";
import FindInvoice from "../common/FindInvoice";
import InvoiceNumber from "../common/InvoiceNumber";
import { ViewContext } from "../contexts/ViewContext";
import VoucherUpdateUseType from "./VoucherUpdateUseType";
import { createPortal } from "react-dom";
import Modal from "../Modal";

export default function VoucherIndividualView({
  item,
  setUpdate,
  update,
}: {
  item: any;
  setUpdate: Function;
  update: number;
}) {
  const {
    id,
    number,
    paid,
    amount,
    number_text,
    valid_until,
    request_user,
    status: statusPick,
    print_date,
    sold_date,
    client_used_date,
    received_date,
    received_user_id,
    sell_document_lsi_id,
    sell_document_number,
    sell_document_type,
    client_received_name,
    client_used_kind,
  } = item;

  const { auth: authAll } = useContext(UserContext);
  const auth = objectOrJSONparse(objectOrJSONparse(authAll)?.modules)?.vouchers;
  const [status, setStatus] = useState(statusPick);
  const { setModal2, modal2 } = useContext(ViewContext);

  const [salesDocumentData, setSalesDocumentData] = useState<any>({});
  const [salesDocumentSelected, setSalesDocumentSelected] = useState(true);
  const [isInvoice, setIsInvoice] = useState(
    sell_document_type === 2 ? false : true
  );
  const [voucherUseTypeOptions, setVoucherUseTypeOptions] = useState(false);
  const [currentType, setCurrentType] = useState<string | null>(
    client_used_kind
  );

  function save(r: any) {
    const { SCID, ko_firma, DocNo, full_number, overrideType } = r;
    const input = {
      voucher_id: item.id,
      type: overrideType != undefined ? overrideType : isInvoice ? 1 : 2,
      number: full_number, //isInvoice ? `FV/${DocNo}/${month}/${year}/H` : `PAR/${DocNo}`,
      lsi_id: SCID,
      customer: ko_firma,
    };
    console.log(salesDocumentData, input);
    setVoucherSalesData(input);
    setUpdate((prev: number) => prev + 1);
  }

  function setVoucherSalesData({
    voucher_id,
    type,
    number,
    lsi_id,
    customer,
  }: {
    voucher_id: number;
    type: number;
    number: string;
    lsi_id: number;
    customer: string;
  }) {
    insertGeneric("set-voucher-sales-data", {
      id: voucher_id,
      sell_document_type: type,
      sell_document_number: number,
      sell_document_lsi_id: lsi_id,
      client_received_name: customer,
    });
  }

  useEffect(() => {
    setSalesDocumentData({
      SCID: sell_document_lsi_id,
      ko_firma: client_received_name,
      full_number: sell_document_number,
      DocNo: sell_document_type
        ? sell_document_number?.replace(/FV\/([0-9]*)\/.*/, "$1")
        : sell_document_number?.replace(/PAR\/([0-9]*)/, "$1"),
    });
    setSalesDocumentSelected(true);
  }, [item?.id]);

  async function updateStatus(id: number, toStatus: number, revert?: Boolean) {
    await insertGeneric("set-voucher-status-individual", {
      id: id,
      status: toStatus,
      revert: revert,
    });

    if (!paid && (toStatus === 3 || toStatus === 2)) {
      await insertGeneric("update-lsi-voucher-number", {
        vouchers: [
          {
            amount: amount,
            paid: paid,
            copies: 1,
            group_info: number_text,
          },
        ],
        remove: toStatus === 3 ? true : undefined,
      });
    }

    setUpdate((prev: number) => prev + 1);
    setStatus(toStatus);
  }

  useEffect(() => {
    setStatus(statusPick);
    setCurrentType(client_used_kind);
    setVoucherUseTypeOptions(false);
  }, [id]);

  return (
    <div className="voucher-view-wrap">
      <div className="voucher-view">
        <div className="voucher-number">{number_text}</div>
        <div className="details-and-options">
          <div>
            <div className="voucher-item">
              <div>Osoba zamawiająca</div>
              <div>{request_user}</div>
            </div>
            <div className="voucher-item">
              <div>Termin ważności</div>
              <div>{prettyDate(valid_until, "", true)}</div>
            </div>
            <div className="voucher-item">
              <div>Data wydruku</div>
              <div>{status >= 1 && prettyDate(print_date)}</div>
            </div>
            <div className="voucher-item">
              <div>Data przyjęcia w hotelu</div>
              <div>{status >= 2 && prettyDate(received_date)}</div>
            </div>
            <div className="voucher-item">
              <div>Data wydania klientowi</div>
              <div>{status >= 3 && prettyDate(sold_date)}</div>
            </div>
            {/* <div className="voucher-item">
              <div>Nazwa klienta</div>
              // <VoucherField /> 
            </div> */}
            <div className="voucher-item">
              <div>
                <div>Dokument sprzedaży</div>
                {!salesDocumentSelected ? (
                  <button
                    className="normal-button smaller"
                    onClick={() => setSalesDocumentSelected(true)}
                  >
                    Cofnij
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div>
                {salesDocumentData?.SCID || !salesDocumentSelected ? (
                  <>
                    <div style={{ display: "flex" }}>
                      <button
                        onClick={() =>
                          !salesDocumentSelected && setIsInvoice(true)
                        }
                        className={
                          "normal-button smaller" + (isInvoice ? " active" : "")
                        }
                        style={
                          !isInvoice && salesDocumentSelected
                            ? { display: "none" }
                            : {}
                        }
                      >
                        Faktura
                      </button>
                      <button
                        onClick={() =>
                          !salesDocumentSelected && setIsInvoice(false)
                        }
                        className={
                          "normal-button smaller" +
                          (!isInvoice ? " active" : "")
                        }
                        style={
                          isInvoice && salesDocumentSelected
                            ? { display: "none" }
                            : {}
                        }
                      >
                        Paragon
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {salesDocumentSelected ? (
                  <div style={{ display: "flex" }}>
                    <button
                      className="normal-button"
                      style={{
                        padding: "3px 10px",
                        margin: "2px",
                        background: "#d8d8b9",
                        borderRadius: "5px",
                      }}
                      onClick={() => setSalesDocumentSelected(false)}
                    >
                      {salesDocumentData?.SCID ? <>Zmień</> : <>Dodaj</>}
                    </button>
                    {salesDocumentData?.SCID ? (
                      <button
                        className="normal-button"
                        style={{
                          padding: "3px 10px",
                          margin: "2px",
                          background: "transparent",
                          borderRadius: "5px",
                          color: "black",
                        }}
                        onClick={() => {
                          setSalesDocumentData({});
                          save({
                            // SCID, ko_firma, DocNo, full_number, overrideType
                            overrideType: null,
                            full_number: null,
                            SCID: null,
                            ko_firma: null,
                          });
                        }}
                      >
                        Wyczyść
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <>
                    <FindInvoice
                      data={salesDocumentData}
                      setData={setSalesDocumentData}
                      save={save}
                      hide={setSalesDocumentSelected}
                      isInvoice={isInvoice}
                    />
                  </>
                )}
              </div>
            </div>
            {salesDocumentSelected ? (
              <>
                <div className="voucher-item">
                  <div>Numer dok. sprzedaży</div>
                  <div
                    title={
                      "SCID=" +
                      salesDocumentData?.SCID +
                      "\n" +
                      "SCPIDs=" +
                      salesDocumentData?.SCPIDs
                    }
                  >
                    {salesDocumentData?.full_number}
                    {/* {isInvoice ? (
                      <InvoiceNumber
                        item={{
                          DocNo: salesDocumentData?.DocNo,
                          month: salesDocumentData?.month,
                          year: salesDocumentData?.year,
                        }}
                      />
                    ) : (
                      <>PAR/{salesDocumentData?.DocNo}</>
                    )} */}
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="voucher-item">
              <div>Klient</div>
              <div>{salesDocumentData?.ko_firma}</div>
            </div>
            <div className="voucher-item">
              <div>Data wykorzystania przez klienta</div>
              <div>{status === 4 && prettyDate(client_used_date)}</div>
            </div>
            <div className="voucher-item">
              <div>Rodzaj wykorzystanej usługi</div>
              <div className="voucher-use-type-setting">
                <div onClick={() => {
                  setVoucherUseTypeOptions((prev: Boolean) => !prev);
                }}>{currentType || "brak danych"}</div>
                {voucherUseTypeOptions ? (
                  <VoucherUpdateUseType
                    item={item}
                    setUpdate={setUpdate}
                    currentType={currentType}
                    setCurrentType={setCurrentType}
                    show={setVoucherUseTypeOptions}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="voucher-item-option-wrap">
            {status === 1 ? ( // do podania
              <div className="info">
                <p>
                  Jeśli voucher dotarł do hotelu, oznacz jego grupę jako
                  przyjętą.
                </p>
                <p>Spowoduje to dodanie liczby voucherów w grupie do stanu.</p>
              </div>
            ) : status === 2 ? ( //w hotelu
              <>
                <div className="info">
                  <p>
                    Voucher widnieje jako dostępny w hotelu. Jeśli trafił już do
                    klienta, kliknij poniższy przycisk. Jeśli nie jest dostępny
                    w hotelu, zmień opcje grupy.
                  </p>
                </div>
                <button
                  className="normal-button"
                  onClick={() => updateStatus(item?.id, 3)}
                >
                  {paid ? (
                    <>POTWIERDŹ SPRZEDAŻ DOKONANĄ W LSI</>
                  ) : (
                    <>ODNOTUJ PRZEKAZANIE KLIENTOWI</>
                  )}
                </button>
              </>
            ) : status === 3 ? ( // wydany klientowi )
              <div>
                <div className="info">
                  Voucher w rękach klienta, który ma czas na jego wykorzystanie
                  do: <div>{prettyDate(valid_until, "", true)}.</div>
                </div>
                <button
                  className="normal-button"
                  onClick={async () => {
                    setModal2({
                      show: true,
                      heading: "Sposób wykorzystania",
                      content: (
                        <VoucherUpdateUseType
                          item={item}
                          updateStatus={updateStatus}
                          currentType={currentType}
                          setCurrentType={setCurrentType}
                          setUpdate={setUpdate}
                        />
                      ),
                    });
                    // updateStatus(item?.id, 4);
                  }}
                >
                  <>ODNOTUJ WYKORZYSTANIE VOUCHERA PRZEZ KLIENTA</>
                </button>
                <button
                  className="normal-button"
                  onClick={() =>
                    window.confirm("Czy na pewno chcesz cofnąć?") &&
                    updateStatus(item?.id, 2, true)
                  }
                  style={{ fontWeight: "normal", color: "brown" }}
                >
                  <>COFNIJ - VOUCHER JEDNAK NIE ZOSTAŁ WYDANY</>
                </button>
              </div>
            ) : status === 4 ? ( // wykorzystany
              <>
                <div className="info">Klient wykorzystał już ten voucher.</div>
                <button
                  className="normal-button"
                  onClick={() =>
                    window.confirm("Czy na pewno chcesz cofnąć?") &&
                    updateStatus(item?.id, 3, true)
                  }
                  style={{ fontWeight: "normal", color: "brown" }}
                >
                  <>COFNIJ - KLIENT JEDNAK NIE WYKORZYSTAŁ VOUCHERA</>
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
