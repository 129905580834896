import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import employeesIcon from "../../images/icons/employees2.svg";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";

export default function AttendanceWidget({
  userOffice,
}: {
  userOffice?: Boolean;
}) {
  const { userID, role } = useContext(UserContext);

  const seeOffice = [7, 1, 18, 16, 15].includes(Number(userID) as number);

  const { loading, rows } = useGeneric({
    name: "attendance-now",
    reload: true,
    secondsToReload: 120,
  });

  const hotel = rows?.length && rows?.filter((e: any) => e?.location === 0);
  const office = rows?.length && rows?.filter((e: any) => e?.location === 1);

  const current = rows?.length && rows?.filter((e: any) => e?.just_ended === 0);
  const currentHotel =
    current?.length && current?.filter((e: any) => e?.location === 0);
  const currentOffice =
    current?.length && current?.filter((e: any) => e?.location === 1);

  return loading ? (
    <Loading little={true} />
  ) : (
    <div className="employees-current-widget tooltip">
      {rows?.length ? (
        <>
          <div>
            <img src={employeesIcon} />
            {currentHotel?.length} / {currentOffice?.length}
          </div>
          <div
            className="top"
            style={{
              height: "215px",
              width: "330px",
              padding: "2px 4px",
              top: "238px",
              left: "150px",
              overflowY: "auto",
              textAlign: "left",
              zIndex: 10,
            }}
          >
            <div>OBECNI TERAZ</div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: seeOffice ? "1fr 1fr" : "1fr",
                gap: "15px",
                marginTop: "5px",
              }}
            >
              <div>
                {seeOffice ? <strong>HOTEL</strong> : <></>}
                {hotel?.map((e: any) => (
                  <EmployeeCard e={e} key={e?.id} />
                ))}
              </div>
              { seeOffice ? (
                <div>
                  <strong>BIURO</strong>
                  {office?.map((e: any) => (
                    <EmployeeCard e={e} key={e?.id} />
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      ) : (
        <>-</>
      )}
    </div>
  );
}

function EmployeeCard({ e }: { e: any }) {
  return (
    <div
      className={
        "employee-current" +
        (e?.dubious === 1 ? " dubious" : "") +
        (e?.just_ended ? " just-ended" : "") +
        (e?.just_started ? " fresh" : "")
      }
    >
      {e?.first_name?.slice(0, 1)}.{e?.last_name?.toUpperCase()}
      <div className="employee-current-time">
        {e?.just_ended ? (
          <>
            <span style={{ fontSize: "6px" }}>➤</span>
            {new Date(e?.t2)?.toLocaleTimeString("pl-PL", {
              hour: "numeric",
              minute: "numeric",
            })}
          </>
        ) : (
          <>
            {new Date(e?.t1)?.toLocaleTimeString("pl-PL", {
              hour: "numeric",
              minute: "numeric",
            })}
            <span style={{ fontSize: "6px" }}>➤</span>
          </>
        )}
      </div>
    </div>
  );
}
